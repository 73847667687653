import { html } from 'lit-html';

export const templateStates = html`
  <paper-item value="Alabma">Alabama</paper-item>
	<paper-item value="Alaska">Alaska</paper-item>
	<paper-item value="Arizona">Arizona</paper-item>
	<paper-item value="Arkansas">Arkansas</paper-item>
	<paper-item value="California">California</paper-item>
	<paper-item value="Colorado">Colorado</paper-item>
	<paper-item value="Connecticut">Connecticut</paper-item>
	<paper-item value="Delaware">Delaware</paper-item>
	<paper-item value="Florida">Florida</paper-item>
	<paper-item value="Georgia">Georgia</paper-item>
	<paper-item value="Hawaii">Hawaii</paper-item>
	<paper-item value="Idaho">Idaho</paper-item>
	<paper-item value="Illinois">Illinois</paper-item>
	<paper-item value="Indiana">Indiana</paper-item>
	<paper-item value="Iowa">Iowa</paper-item>
	<paper-item value="Kansas">Kansas</paper-item>
	<paper-item value="Kentucky">Kentucky</paper-item>
	<paper-item value="Louisiana">Louisiana</paper-item>
	<paper-item value="Maine">Maine</paper-item>
	<paper-item value="Maryland">Maryland</paper-item>
	<paper-item value="Massachusetts">Massachusetts</paper-item>
	<paper-item value="Michigan">Michigan</paper-item>
	<paper-item value="Minnesota">Minnesota</paper-item>
	<paper-item value="Mississippi">Mississippi</paper-item>
	<paper-item value="Missouri">Missouri</paper-item>
	<paper-item value="Montana">Montana</paper-item>
	<paper-item value="Nebraska">Nebraska</paper-item>
	<paper-item value="Nevada">Nevada</paper-item>
	<paper-item value="New Hampshire">New Hampshire</paper-item>
	<paper-item value="New Jersey">New Jersey</paper-item>
	<paper-item value="New Mexico">New Mexico</paper-item>
	<paper-item value="New York">New York</paper-item>
	<paper-item value="North Carolina">North Carolina</paper-item>
	<paper-item value="North Dakota">North Dakota</paper-item>
	<paper-item value="Ohio">Ohio</paper-item>
	<paper-item value="Oklahoma">Oklahoma</paper-item>
	<paper-item value="Oregon">Oregon</paper-item>
	<paper-item value="Pennsylvania">Pennsylvania</paper-item>
	<paper-item value="Rhode Island">Rhode Island</paper-item>
	<paper-item value="South Carolina">South Carolina</paper-item>
	<paper-item value="South Dakota">South Dakota</paper-item>
	<paper-item value="Tennessee">Tennessee</paper-item>
	<paper-item value="Texas">Texas</paper-item>
	<paper-item value="Utah">Utah</paper-item>
	<paper-item value="Vermont">Vermont</paper-item>
	<paper-item value="Virginia">Virginia</paper-item>
	<paper-item value="Washington">Washington</paper-item>
	<paper-item value="West Virginia">West Virginia</paper-item>
	<paper-item value="Wisconsin">Wisconsin</paper-item>
	<paper-item value="Wyoming">Wyoming</paper-item>
`;
